import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVehicleDriver } from "../../../../../models/schema/vehicle/vehicle.model";

interface VehicleDriverState {
  drivers: IVehicleDriver[];
  driverToEdit: IVehicleDriver | null;
}

const initialState: VehicleDriverState  = {
  drivers: [],
  driverToEdit: null
}

const driverSlice = createSlice({
  name: "Driver",
  initialState,
  reducers: {
    setDriverList: (state, action: PayloadAction<IVehicleDriver[]>) => {
      state.drivers = action.payload;
    },
    concatListDriver: (state: VehicleDriverState, action: PayloadAction<IVehicleDriver[]>) => {
      state.drivers = [...state.drivers.concat(action.payload)];
    },
    setDriverToEdit: (state, action: PayloadAction<IVehicleDriver | null>) => {
      state.driverToEdit = action.payload;
    },
    addDriver: (state, action: PayloadAction<IVehicleDriver>) => {
      state.drivers.push(action.payload);
    },
    updateDriver: (state, action: PayloadAction<IVehicleDriver>) => {
      let itemIndex = state.drivers.findIndex(
        item => item.id === action.payload.id
      );
      if (itemIndex !== -1) state.drivers[itemIndex] = action.payload;
    },
    deleteDriver: (state, action: PayloadAction<number>) => {
      const newArray = state.drivers.filter(
        item => item.id !== action.payload
      );
      state.drivers = newArray;
    },
  },
});

export const {
  setDriverList,
  concatListDriver,
  setDriverToEdit,
  addDriver,
  updateDriver,
  deleteDriver,
} = driverSlice.actions;

export default driverSlice.reducer;