import { IUser } from "../../../../models/schema/user/user.model";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAuthState {
  isLoggedIn: boolean;
  user: IUser | null;
  token: string;
}

const initialState: IAuthState = {
  isLoggedIn: false,
  user: null,
  token: ""
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setUser: (state, action: PayloadAction<IUser | null>) => {
      state.user = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const { 
  setIsLoggedIn, 
  setUser,
  setToken
} = authSlice.actions;

export default authSlice.reducer;