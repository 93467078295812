import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICustomer } from "../../../../../models/schema/customer/customer.model";

interface CustomerState {
  customers: ICustomer[];
  customerToEdit: ICustomer | null;
}

const initialState: CustomerState  = {
  customers: [],
  customerToEdit: null
}

const customerSlice = createSlice({
  name: "Customer",
  initialState,
  reducers: {
    setCustomerList: (state, action: PayloadAction<ICustomer[]>) => {
      state.customers = action.payload;
    },
    concatListCustomer: (state: CustomerState, action: PayloadAction<ICustomer[]>) => {
      state.customers = [...state.customers.concat(action.payload)];
    },
    setCustomerToEdit: (state, action: PayloadAction<ICustomer | null>) => {
      state.customerToEdit = action.payload;
    },
    addCustomer: (state, action: PayloadAction<ICustomer>) => {
      state.customers.push(action.payload);
    },
    updateCustomer: (state, action: PayloadAction<ICustomer>) => {
      let itemIndex = state.customers.findIndex(
        item => item.id === action.payload.id
      );
      if (itemIndex !== -1) state.customers[itemIndex] = action.payload;
    },
    deleteCustomer: (state, action: PayloadAction<number>) => {
      const newArray = state.customers.filter(
        item => item.id !== action.payload
      );
      state.customers = newArray;
    },
  },
});

export const {
  setCustomerList,
  concatListCustomer,
  setCustomerToEdit,
  addCustomer,
  updateCustomer,
  deleteCustomer,
} = customerSlice.actions;

export default customerSlice.reducer;