import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import customerSlice from "./slices/customerSlice";
import vehicleTypeSlice from "./slices/vehicleTypeSlice";
import driverSlice from "./slices/driverSlice";
import propertySlice from "./slices/propertySlice";
import parcelSlice from "./slices/parcelSlice";
import fruitTicketSlice from "./slices/fruitTicketSlice";

export default combineReducers({
  user: userSlice,
  customer: customerSlice,
  vehicleType: vehicleTypeSlice,
  driver: driverSlice,
  property: propertySlice,
  parcel: parcelSlice,
  fruitTicket: fruitTicketSlice
});