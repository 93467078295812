import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProperty } from "../../../../../models/schema/property/property.model";

interface PropertyState {
  properties: IProperty[];
  propertyToEdit: IProperty | null;
}

const initialState: PropertyState  = {
  properties: [],
  propertyToEdit: null
}

const propertySlice = createSlice({
  name: "Property",
  initialState,
  reducers: {
    setPropertyList: (state, action: PayloadAction<IProperty[]>) => {
      state.properties = action.payload;
    },
    concatListProperty: (state: PropertyState, action: PayloadAction<IProperty[]>) => {
      state.properties = [...state.properties.concat(action.payload)];
    },
    setPropertyToEdit: (state, action: PayloadAction<IProperty | null>) => {
      state.propertyToEdit = action.payload;
    },
    addProperty: (state, action: PayloadAction<IProperty>) => {
      state.properties.push(action.payload);
    },
    updateProperty: (state, action: PayloadAction<IProperty>) => {
      let itemIndex = state.properties.findIndex(
        item => item.id === action.payload.id
      );
      if (itemIndex !== -1) state.properties[itemIndex] = action.payload;
    },
    deleteProperty: (state, action: PayloadAction<number>) => {
      const newArray = state.properties.filter(
        item => item.id !== action.payload
      );
      state.properties = newArray;
    },
  },
});

export const {
  setPropertyList,
  concatListProperty,
  setPropertyToEdit,
  addProperty,
  updateProperty,
  deleteProperty,
} = propertySlice.actions;

export default propertySlice.reducer;