"use client"
import { Link } from 'react-router-dom'
import { slideToggle } from './../../composables/slideToggle';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { RootState } from '../../../store/reducers';

const SidebarProfile = () => {

  const { user } = useAppSelector((state: RootState) => state.auth);

  const handleProfileExpand = (e: any) => {

    e.preventDefault();

    let targetSidebar = document.querySelector('.app-sidebar:not(.app-sidebar-end)');
    let targetMenu = e.target.closest('.menu-profile');
    let targetProfile = document.querySelector('#appSidebarProfileMenu') as HTMLInputElement;
    let expandTime = (targetSidebar && targetSidebar.getAttribute('data-disable-slide-animation')) ? 0 : 0;

    if (targetProfile) {
      if (targetProfile.style.display === 'block') {
        targetMenu.classList.remove('active');
      } else {
        targetMenu.classList.add('active');
      }
      slideToggle(targetProfile, expandTime);
      targetProfile.classList.toggle('expand');
    }
  }

  return (
    <div className="menu">
      <div className="menu-profile">
        <Link to="/" onClick={handleProfileExpand} className="menu-profile-link">
          <div className="menu-profile-cover with-shadow"></div>
          <div className="menu-profile-image menu-profile-image-icon bg-gray-900 text-gray-600">
            <i className="fa fa-user"></i>
          </div>
          <div className="menu-profile-info">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                { user?.username ?? "" }
              </div>
              <div className="menu-caret ms-auto"></div>
            </div>
            <small>{ user?.user_app?.name ?? "" }</small>
          </div>
        </Link>
      </div>
      <div id="appSidebarProfileMenu" className="collapse">
        <div className="menu-item pt-5px">
          <Link to="/" className="menu-link">
            <div className="menu-icon"><i className="fa fa-cog"></i></div>
            <div className="menu-text">Ajustes</div>
          </Link>
        </div>
        <div className="menu-item">
          <Link to="/" className="menu-link">
            <div className="menu-icon"><i className="fa fa-pencil-alt"></i></div>
            <div className="menu-text"> Enviar Feedback</div>
          </Link>
        </div>
        <div className="menu-item pb-5px">
          <Link to="/" className="menu-link">
            <div className="menu-icon"><i className="fa fa-question-circle"></i></div>
            <div className="menu-text"> Ayuda</div>
          </Link>
        </div>
        <div className="menu-divider m-0"></div>
      </div>
    </div>
  );
}

export default SidebarProfile;