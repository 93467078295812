export interface IMenu {
  menuId: number;
  menuIcon: string;
  name: string;
  menuType: string;
  order: number;
  groups: IGroup[];
}

export interface IGroup {
  groupId: number;
  groupIcon: string;
  name: string;
  description: string;
  items: IItem[];
  order: number;
}

export interface IItem {
  menuId: number;
  itemIcon: string;
  name: string;
  pathURL: string;
  order: number;
}

export interface IPageAccess {
  pageURL: string;
  elementRefs: string[];
}

export enum MenuType {
  SIDEBAR = "SIDEBAR",
  CONFIGURATION = "CONFIGURATION"
}