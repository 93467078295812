import useDropdownProfile from "./useProfile";
import { Link } from "react-router-dom";

const DropdownProfile = () => {

  const {
    isLoading,
    user,
    handleLogOut
  } = useDropdownProfile();

  return (
    <div className="navbar-item navbar-user dropdown">
      <a href="#" className="navbar-link d-flex align-items-center" data-bs-toggle="dropdown">
        <span>
          <span className="d-none d-md-inline">{ user?.username ?? "" }</span>
          <b className="caret"></b>
        </span>
        <div className="image image-icon bg-gray-800 text-gray-600">
          <i className="fa fa-user"></i>
        </div>
      </a>
      <div className="dropdown-menu dropdown-menu-end me-1">
        {/* <a href="#/" className="dropdown-item">Edit Profile</a>
        <a href="#/" className="dropdown-item d-flex align-items-center">
            Inbox
            <span className="badge bg-danger rounded-pill ms-auto pb-4px">2</span> 
        </a>
        <a href="#/" className="dropdown-item">Calendar</a>
        <a href="#/" className="dropdown-item">Settings</a> */}
        <div className="dropdown-divider"></div>

        {!isLoading ? (
          <Link onClick={handleLogOut} to="#" className="dropdown-item">
            <i className="fa fa-power-off me-1 text-danger" />
            <span>Cerrar Sesión</span>
          </Link>
        ) : (
          <Link to="#" className="dropdown-item">
            <i className="fa fa-hourglass fa-spin me-1 text-danger" />
            <span>Saliendo...</span>
          </Link>
        )}
      </div>
    </div>
  );
}

export default DropdownProfile;