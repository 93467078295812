import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../../../../models/schema/user/user.model";

interface UserState {
  users: IUser[];
  userToEdit: IUser | null;
}

const initialState: UserState  = {
  users: [],
  userToEdit: null
}

const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    setUserList: (state, action: PayloadAction<IUser[]>) => {
      state.users = action.payload;
    },
    concatListUser: (state: UserState, action: PayloadAction<IUser[]>) => {
      state.users = [...state.users.concat(action.payload)];
    },
    setUserToEdit: (state, action: PayloadAction<IUser | null>) => {
      state.userToEdit = action.payload;
    },
    addUser: (state, action: PayloadAction<IUser>) => {
      state.users.push(action.payload);
    },
    updateUser: (state, action: PayloadAction<IUser>) => {
      let itemIndex = state.users.findIndex(
        item => item.id === action.payload.id
      );
      if (itemIndex !== -1) state.users[itemIndex] = action.payload;
    },
    deleteUser: (state, action: PayloadAction<string>) => {
      const newArray = state.users.filter(
        item => item.id !== action.payload
      );
      state.users = newArray;
    },
  },
});

export const {
  setUserList,
  concatListUser,
  setUserToEdit,
  addUser,
  updateUser,
  deleteUser,
} = userSlice.actions;

export default userSlice.reducer;