import { Suspense } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../store/reducers";
import Layout from "../../components/layout";
import { LOGIN_PAGE } from "../routes";
import SyncLoader from "react-spinners/SyncLoader";

const PrivateRoutes = () => {

  const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);

  return (
    isLoggedIn ?  (
      <Layout>
        <Suspense
          fallback={
            <div className="pt-5 d-flex justify-content-center">
              <SyncLoader size={20} margin={10} color={"#5156be"} />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </Layout>
    ) : (
      <Navigate to={LOGIN_PAGE} />
    )
  )
}

export default PrivateRoutes;