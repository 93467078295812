import React from "react";
import { Helmet } from "react-helmet-async";
import { Formik, Form, Field } from 'formik';
import useLogin from "./useLogin";
import { Spinner } from "reactstrap";

export default function Login() {

  const {
    SingInSchema,
    onFormSubmit
  } = useLogin();

  return (
    <React.Fragment>
      <Helmet>
        <title>INSEMAP | Login</title>
      </Helmet>
      <div className="app app-without-header">
        <div className="p-0">
          <div className="login login-v1">
            <div className="login-container">

              <div className="login-header">
                <div className="brand">
                  <div className="d-flex align-items-center">
                    <span className="logos">
                      {/* <img src={logo} alt="logo" width="80" /> */}
                    </span>&nbsp;<b>INSEMAP</b>&nbsp;Fincas
                  </div>
                  {/* <small>Sistema ERP Dualtech</small> */}
                </div>
                <div className="icon">
                  <i className="fa fa-lock"></i>
                </div>
              </div>

              <div className="login-body">
                <div className="login-content fs-13px">
                  <Formik
                    initialValues={{ username: '', password: '' }}
                    validationSchema={SingInSchema}
                    onSubmit={onFormSubmit}>
                    {({values, errors, touched, isSubmitting}) => (
                      <Form className="custom-form pt-2">
                        <div className="mb-3">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <label className="form-label">Usuario</label>
                            </div>
                          </div>
                          <Field
                            id="username"
                            name="username"
                            type="text"
                            value={values.username}
                            className={"form-control " + (errors.username ? " is-invalid" : "")}
                            placeholder="Ingrese su usuario"
                          />
                          { errors.username && (<label className="text-danger">{String(errors?.username)}</label>) }
                        </div>
                        <div className="mb-3">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <label className="form-label">Contraseña</label>
                            </div>
                          </div>
                          <div className="mb-3">
                            <Field
                              id="password"
                              name="password"
                              type="password"
                              maxLength={100}
                              value={values.password}
                              className={"form-control " + (errors.password ? " is-invalid" : "")}
                              placeholder="Ingrese la contraseña" 
                            />
                            { errors.password && (<label className="text-danger">{String(errors.password)}</label>) }
                          </div>
                        </div>
                        <div className="mb-3">
                          <button
                            className="btn h-45px btn-primary d-block w-100 btn-lg"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            { isSubmitting && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-1"
                              />
                            )}
                            Iniciar Sesión
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}