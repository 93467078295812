import { useEffect, useState } from "react";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";

const Layout = (props: any) => {

  const [sideBarToggled, setSideBarToggled] = useState<boolean>(false);
  const [sideBarMobileToggled, setSideBarMobileToggled] = useState<string>();

  useEffect(() => {
    setSideBarMobileToggled(sideBarToggled ? "app-sidebar-mobile-toggled" : "");
  }, [sideBarToggled])

  const handleSideBarToggle = () => {
    setSideBarToggled(!sideBarToggled);
  }

  return (
    <div className={`app app-header-fixed app-sidebar-fixed ${sideBarMobileToggled}`}>
      <Sidebar />
      <Header
        handleSideBarToggle={handleSideBarToggle} 
      />
      <div className="app-content">
        {props.children}
      </div>
    </div>
  )
}

export default Layout;