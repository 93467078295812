import React, { useEffect } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import SidebarProfile from "./SidebarProfile";
import SidebarNav from "./SidebarNav";

import { SideBarMenu } from "./Sidebar.model";
import { slideToggle } from "../../composables/slideToggle";
import { slideUp } from "../../composables/slideUp";
import { slideDown } from "../../composables/slideDown";

const Sidebar = () => {

  const menu = SideBarMenu;
  let appSidebarFloatSubmenuTimeout: any = '';
  let appSidebarFloatSubmenuDom: any = '';

  useEffect(() => {
    if(menu?.length == 0) return
    let targetSidebar = document.querySelector('.app-sidebar:not(.app-sidebar-end)');
    let expandTime = (targetSidebar && targetSidebar.getAttribute('data-disable-slide-animation')) ? 0 : 0;

    let menuBaseSelector = '.app-sidebar .menu > .menu-item.has-sub';
    let submenuBaseSelector = ' > .menu-submenu > .menu-item.has-sub';

    // menu
    let menuLinkSelector =  menuBaseSelector + ' > .menu-link';
    let menus = [].slice.call(document.querySelectorAll(menuLinkSelector));
    handleSidebarMenuToggle(menus, expandTime);

    // submenu lvl 1
    let submenuLvl1Selector = menuBaseSelector + submenuBaseSelector;
    let submenusLvl1 = [].slice.call(document.querySelectorAll(submenuLvl1Selector + ' > .menu-link'));
    handleSidebarMenuToggle(submenusLvl1, expandTime);

    // submenu lvl 2
    let submenuLvl2Selector = menuBaseSelector + submenuBaseSelector + submenuBaseSelector;
    let submenusLvl2 = [].slice.call(document.querySelectorAll(submenuLvl2Selector + ' > .menu-link'));
    handleSidebarMenuToggle(submenusLvl2, expandTime);

    return () => {

    }

  }, [menu])

  const handleSidebarMenuToggle = (menus: any, expandTime: any) => {

    menus.map(function(menu: any) {
        menu.onclick = function(e: any) {
            // console.log('menu', menu)
            e.preventDefault();
            var target = this.nextElementSibling;
            
            menus.map(function(m: any) {
                var otherTarget = m.nextElementSibling;
                if (otherTarget !== target) {
                    slideUp(otherTarget, expandTime);
                    otherTarget.closest('.menu-item').classList.remove('expand');
                    otherTarget.closest('.menu-item').classList.add('closed');
                }
                return true;
            });
            
            var targetItemElm = target.closest('.menu-item');
            
            // console.log("target", targetItemElm)
            if (targetItemElm.classList.contains('expand') || (targetItemElm.classList.contains('active') && !target.style.display)) {
                // console.log("close")
                targetItemElm.classList.remove('expand');
                targetItemElm.classList.add('closed');
                slideToggle(target, expandTime);
            } else {
                // console.log("expand")
                targetItemElm.classList.add('expand');
                targetItemElm.classList.remove('closed');
                slideToggle(target, expandTime);
            }
        }
        
        return true;
    });
  }

  const handleGetHiddenMenuHeight = (elm: any) => {
    elm.setAttribute('style', 'position: absolute; visibility: hidden; display: block !important');
    var targetHeight  = elm.clientHeight;
    elm.removeAttribute('style');
    return targetHeight;
  }

  const handleSidebarMinifyFloatMenuClick = () => {

    let elms = [].slice.call(document.querySelectorAll('#app-sidebar-float-submenu .menu-item.has-sub > .menu-link'));
    if (elms) {
        elms.map(function(elm: any) {
            elm.onclick = function(e: any) {
                // console.log("click")
                e.preventDefault();
                let targetItem = this.closest('.menu-item');
                let target = targetItem.querySelector('.menu-submenu');
                let targetStyle = getComputedStyle(target);
                let close = (targetStyle.getPropertyValue('display') !== 'none') ? true : false;
                let expand = (targetStyle.getPropertyValue('display') !== 'none') ? false : true;
        
                // slideToggle(target);
        
                let loopHeight = setInterval(function() {
                    let targetMenu: any = document.querySelector('#app-sidebar-float-submenu');
                    let targetMenuArrow: any = document.querySelector('#app-sidebar-float-submenu-arrow');
                    let targetMenuLine: any = document.querySelector('#app-sidebar-float-submenu-line');
                    let targetHeight = targetMenu.clientHeight;
                    let targetOffset = targetMenu.getBoundingClientRect();
                    let targetOriTop = targetMenu.getAttribute('data-offset-top');
                    let targetMenuTop = targetMenu.getAttribute('data-menu-offset-top');
                    let targetTop 	 = targetOffset.top;
                    let windowHeight = document.body.clientHeight;
                    if (close) {
                        if (targetTop > targetOriTop) {
                            targetTop = (targetTop > targetOriTop) ? targetOriTop : targetTop;
                            targetMenu.style.top = targetTop + 'px';
                            targetMenu.style.bottom = 'auto';
                            targetMenuArrow.style.top = '20px';
                            targetMenuArrow.style.bottom = 'auto';
                            targetMenuLine.style.top = '20px';
                            targetMenuLine.style.bottom = 'auto';
                        }
                    }
                    if (expand) {
                        if ((windowHeight - targetTop) < targetHeight) {
                            var arrowBottom = (windowHeight - targetMenuTop) - 22;
                            targetMenu.style.top = 'auto';
                            targetMenu.style.bottom = 0;
                            targetMenuArrow.style.top = 'auto';
                            targetMenuArrow.style.bottom = arrowBottom + 'px';
                            targetMenuLine.style.top = '20px';
                            targetMenuLine.style.bottom = arrowBottom + 'px';
                        }
                        var floatSubmenuElm = document.querySelector('#app-sidebar-float-submenu .app-sidebar-float-submenu');
                        if (targetHeight > windowHeight) {
                            if (floatSubmenuElm) {
                                var splitClass = ('overflow-scroll mh-100vh').split(' ');
                                for (var i = 0; i < splitClass.length; i++) {
                                    floatSubmenuElm.classList.add(splitClass[i]);
                                }
                            }
                        }
                    }
                }, 1);
                setTimeout(function() {
                    clearInterval(loopHeight);
                }, 250);
            }
            return true;
        });
    }
  }

  const handleSidebarMinifyFloatMenu = () => {
    let elms = [].slice.call(document.querySelectorAll('.app-sidebar .menu > .menu-item.has-sub > .menu-link'));
    if (elms) {
        elms.map(function(elm: any) {
            elm.onmouseenter = function() {
                var appElm = document.querySelector('.app');
                if (appElm && appElm.classList.contains('app-sidebar-minified')) {
                    clearTimeout(appSidebarFloatSubmenuTimeout);
                    var targetMenu = this.closest('.menu-item').querySelector('.menu-submenu');
                    if (appSidebarFloatSubmenuDom === this && document.querySelector('#app-sidebar-float-submenu')) {
                        return;
                    } else {
                        appSidebarFloatSubmenuDom = this;
                    }
                    var targetMenuHtml = targetMenu.innerHTML;
                    if (targetMenuHtml) {
                        var bodyStyle     = getComputedStyle(document.body);
                        var sidebarOffset: any = document?.querySelector('#sidebar')?.getBoundingClientRect();
                        var sidebarWidth: any  = parseInt(`${document?.querySelector('#sidebar')?.clientWidth}`);
                        var sidebarX      = (!appElm.classList.contains('app-sidebar-end') && bodyStyle.getPropertyValue('direction') !== 'rtl') ? (sidebarOffset.left + sidebarWidth) : (document.body.clientWidth - sidebarOffset.left);
                        var targetHeight: any  = handleGetHiddenMenuHeight(targetMenu);
                        var targetOffset  = this.getBoundingClientRect();
                        var targetTop     = targetOffset.top;
                        var targetLeft    = (!appElm.classList.contains('app-sidebar-end') && bodyStyle.getPropertyValue('direction') !== 'rtl') ? sidebarX : 'auto';
                        var targetRight   = (!appElm.classList.contains('app-sidebar-end') && bodyStyle.getPropertyValue('direction') !== 'rtl') ? 'auto' : sidebarX;
                        var windowHeight  = document.body.clientHeight;
                
                        if (!document.querySelector('#app-sidebar-float-submenu')) {
                            var overflowClass = '';
                            if (targetHeight > windowHeight) {
                                overflowClass = 'overflow-scroll mh-100vh';
                            }
                            var html = document.createElement('div');
                            html.setAttribute('id', 'app-sidebar-float-submenu');
                            html.setAttribute('class', 'app-sidebar-float-submenu-container');
                            html.setAttribute('data-offset-top', targetTop);
                            html.setAttribute('data-menu-offset-top', targetTop);
                            html.innerHTML = ''+
                            '	<div class="app-sidebar-float-submenu-arrow" id="app-sidebar-float-submenu-arrow"></div>'+
                            '	<div class="app-sidebar-float-submenu-line" id="app-sidebar-float-submenu-line"></div>'+
                            '	<div class="app-sidebar-float-submenu '+ overflowClass +'">'+ targetMenuHtml + '</div>';
                            appElm.appendChild(html);
                    
                            let elm: any = document.getElementById('app-sidebar-float-submenu');
                            elm.onmouseover = function() {
                                clearTimeout(appSidebarFloatSubmenuTimeout);
                            };
                            elm.onmouseout = function() {
                                appSidebarFloatSubmenuTimeout = setTimeout(() => {
                                    document?.querySelector('#app-sidebar-float-submenu')?.remove();
                                }, 250);
                            };
                        } else {
                            let floatSubmenu = document.querySelector('#app-sidebar-float-submenu');
                            let floatSubmenuInnerElm = document.querySelector('#app-sidebar-float-submenu .app-sidebar-float-submenu');
                    
                            if (targetHeight > windowHeight) {
                                if (floatSubmenuInnerElm) {
                                    var splitClass = ('overflow-scroll mh-100vh').split(' ');
                                    for (var i = 0; i < splitClass.length; i++) {
                                        floatSubmenuInnerElm.classList.add(splitClass[i]);
                                    }
                                }
                            }
                            floatSubmenu?.setAttribute('data-offset-top', targetTop);
                            floatSubmenu?.setAttribute('data-menu-offset-top', targetTop);
                            if(floatSubmenuInnerElm)
                                floatSubmenuInnerElm.innerHTML = targetMenuHtml;
                        }
        
                        let targetSubmenuHeight: any = document?.querySelector('#app-sidebar-float-submenu')?.clientHeight;
                        let floatSubmenuElm: any = document.querySelector('#app-sidebar-float-submenu');
                        let floatSubmenuArrowElm: any = document.querySelector('#app-sidebar-float-submenu-arrow');
                        let floatSubmenuLineElm: any = document.querySelector('#app-sidebar-float-submenu-line');
                        if ((windowHeight - targetTop) > targetSubmenuHeight) {
                            if (floatSubmenuElm) {
                                floatSubmenuElm.style.top = targetTop + 'px';
                                floatSubmenuElm.style.left = targetLeft + 'px';
                                floatSubmenuElm.style.bottom = 'auto';
                                floatSubmenuElm.style.right = targetRight + 'px';
                            }
                            if (floatSubmenuArrowElm) {
                                floatSubmenuArrowElm.style.top = '20px';
                                floatSubmenuArrowElm.style.bottom = 'auto';
                            }
                            if (floatSubmenuLineElm) {
                                floatSubmenuLineElm.style.top = '20px';
                                floatSubmenuLineElm.style.bottom = 'auto';
                            }
                        } else {
                            var arrowBottom = (windowHeight - targetTop) - 21;
                            if (floatSubmenuElm) {
                                floatSubmenuElm.style.top = 'auto';
                                floatSubmenuElm.style.left = targetLeft + 'px';
                                floatSubmenuElm.style.bottom = 0;
                                floatSubmenuElm.style.right = targetRight + 'px';
                            }
                            if (floatSubmenuArrowElm) {
                                floatSubmenuArrowElm.style.top = 'auto';
                                floatSubmenuArrowElm.style.bottom = arrowBottom + 'px';
                            }
                            if (floatSubmenuLineElm) {
                                floatSubmenuLineElm.style.top = '20px';
                                floatSubmenuLineElm.style.bottom = arrowBottom + 'px';
                            }
                        }
                        handleSidebarMinifyFloatMenuClick();
                    } else {
                        document?.querySelector('#app-sidebar-float-submenu-line')?.remove();
                        appSidebarFloatSubmenuDom = '';
                    }
                }
            }
            elm.onmouseleave = function() {
                var elm = document.querySelector('.app');
                if (elm && elm.classList.contains('app-sidebar-minified')) {
                    appSidebarFloatSubmenuTimeout = setTimeout(() => {
                        var elm = document.querySelector('#app-sidebar-float-submenu-line');
                        if (elm) {
                            elm.remove();
                        }
                        appSidebarFloatSubmenuDom = '';
                    }, 250);
                }
            }
            return true;
        });
    }
  };

  return(
    <React.Fragment>
      <div id="sidebar" className={'app-sidebar'}>
        <PerfectScrollbar className="app-sidebar-content" options={{suppressScrollX: true}}>
          <SidebarProfile/>
          <SidebarNav />
        </PerfectScrollbar>
      </div>
    </React.Fragment>
  );
}

export default Sidebar;