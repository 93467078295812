"use client"
import React from 'react';
import { Link } from 'react-router-dom'
import DropdownProfile from './dropdown/Profile';

interface IProps {
  handleSideBarToggle: () => void;
}

const Header = ({ handleSideBarToggle }: IProps) => {
    
  return (
    <div id="header" className="app-header">
      <div className="navbar-header">
        <Link to="/" className="navbar-brand">
          {/* <span className="navbar-logo">
            <img src={logo} alt={'logo'} width="150" />
          </span>  */}
          <b>INSEMAP</b>&nbsp;Fincas
        </Link>
        <button type="button" className="navbar-mobile-toggler" data-toggle="app-sidebar-mobile" onClick={handleSideBarToggle}>
          <i className="icon-bar" />
          <i className="icon-bar" />
          <i className="icon-bar" />
        </button>
      </div>
      <div className="navbar-nav">
        {/* <DropdownMegaMenu /> */}
        {/* <StoresFilter />
        <SearchForm /> */}
        <DropdownProfile />
      </div>
    </div>
  );
}

export default Header;