import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFruitDeliveryTicket } from "../../../../../models/schema/document/document.model";

interface FruitTicketState {
  fruitTickets: IFruitDeliveryTicket[];
  fruitTicketToEdit: IFruitDeliveryTicket | null;
}

const initialState: FruitTicketState  = {
  fruitTickets: [],
  fruitTicketToEdit: null
}

const fruitTicketSlice = createSlice({
  name: "FruitTicket",
  initialState,
  reducers: {
    setFruitTicketList: (state, action: PayloadAction<IFruitDeliveryTicket[]>) => {
      state.fruitTickets = action.payload;
    },
    concatListFruitTicket: (state: FruitTicketState, action: PayloadAction<IFruitDeliveryTicket[]>) => {
      state.fruitTickets = [...state.fruitTickets.concat(action.payload)];
    },
    setFruitTicketToEdit: (state, action: PayloadAction<IFruitDeliveryTicket | null>) => {
      state.fruitTicketToEdit = action.payload;
    },
    addFruitTicket: (state, action: PayloadAction<IFruitDeliveryTicket>) => {
      state.fruitTickets.push(action.payload);
    },
    updateFruitTicket: (state, action: PayloadAction<IFruitDeliveryTicket>) => {
      let itemIndex = state.fruitTickets.findIndex(
        item => item.id === action.payload.id
      );
      if (itemIndex !== -1) state.fruitTickets[itemIndex] = action.payload;
    },
    deleteFruitTicket: (state, action: PayloadAction<number>) => {
      const newArray = state.fruitTickets.filter(
        item => item.id !== action.payload
      );
      state.fruitTickets = newArray;
    },
  },
});

export const {
  setFruitTicketList,
  concatListFruitTicket,
  setFruitTicketToEdit,
  addFruitTicket,
  updateFruitTicket,
  deleteFruitTicket,
} = fruitTicketSlice.actions;

export default fruitTicketSlice.reducer;