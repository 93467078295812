import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from '../../../hooks/useAppSelector';
import AuthAPIService from '../../../api/auth/API_AuthService';
import { IIPCResponse } from '../../../../models/shared/response.model';
import { AlertPosition, AlertType, DefaultTitles, alertFromAPIResponse, customAlert } from '../../../services/alert.service';
import { setIsLoggedIn, setToken, setUser } from '../../../store/reducers/auth/authSlice';
import { DASHBOARD } from '../../../routes/routes';

const useLogin = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const SingInSchema = Yup.object().shape({
		username: Yup.string().required('Ingrese el usuario').max(20, 'Máximo 20 caracteres'),
		password: Yup.string().required('Ingrese la contraseña').max(100, 'Máximo 100 caracteres')
	});

  const onFormSubmit = async (values: any, {setSubmitting}: any) => {

    try
    {
      const result = await AuthAPIService.login(values);

      if(!result.success) {
        alertFromAPIResponse({
            success: false,
            message: result.message
          } as IIPCResponse, 
          AlertPosition.topRight
        );
        dispatch(setIsLoggedIn(false));
        dispatch(setUser(null));
        dispatch(setToken(""))
        return;
      }

      dispatch(setIsLoggedIn(true));
      dispatch(setUser(result.data.user));
      dispatch(setToken(result.data.token));
      setSubmitting(false);
      navigate(DASHBOARD);

    }catch(error) {
      customAlert(
        AlertType.error,
        DefaultTitles.MainApp,
        error.message,
        { position: AlertPosition.topRight }
      )
      setSubmitting(false);
    }
  }

  return {
    SingInSchema,
    onFormSubmit
  }
}

export default useLogin;