import React from 'react';
import { Routes, Route} from 'react-router-dom';
import { authRoutes, userRoutes } from './app/routes/routes';
import NotFound from './app/pages/not-found/NotFound';
import PrivateRoutes from './app/routes/middleware/PrivateRoutes';

import "./app/assets/scss/react.scss";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

function App() {
  return (
    <React.Fragment>
      <Routes>
        { authRoutes.map((route, idx) => (
          <Route key={idx} path={route.path} element={route.component} />
        ))}

        <Route element={<PrivateRoutes />}>
          { userRoutes.map((route, idx) => (
            <Route key={idx} path={route.path} element={route.component} />
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />
			</Routes>
    </React.Fragment>
  );
}

export default App;