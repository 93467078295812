import {combineReducers} from 'redux';
import authSlice from './auth/authSlice';
import maintenanceReducer from './maintenance/maintenanceReducer';

const rootReducer = combineReducers({
  auth: authSlice,
  maintenance: maintenanceReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;