import { immediateToast } from "izitoast-react";
import "izitoast-react/dist/iziToast.css";
import { IIPCResponse } from "../../models/shared/response.model";
import Swal, { SweetAlertIcon } from "sweetalert2";

export enum DefaultTitles {
  MainApp = "INSEMAP",
}

export enum DefaultMessages {
  Delete = `¿Estás seguro(a) que deseas eliminar este registro "@param"?`,
  Duplicate = `¿Estás seguro(a) que deseas copiar este registro "@param"?`,
  AddItems = `El usuario no tiene permisos para agregar artículos.`
}

export enum AlertType {
  info = "info",
  error = "error",
  warning = "warning",
  success = "success",
  question = "question",
}

export enum AlertPosition {
  bottomRight = "bottomRight",
  bottomLeft = "bottomLeft",
  topRight = "topRight",
  topLeft = "topLeft",
  topCenter = "topCenter",
  bottomCenter = "bottomCenter",
  center = "center",
}

export class AlertButtons {
  yesText: string;
  noText: string;
  constructor(newItem?: AlertButtons) {
    this.yesText = newItem?.yesText ?? "Sí";
    this.noText = newItem?.yesText ?? "No";
  }
}

const defaultPosition = AlertPosition?.topRight;

const customAlert = (
  type: AlertType,
  title: string,
  message: string,
  options: any
) => {
  const defaults = {
    message: `<b>${message}</b>`,
    title: `<b>${title}</b>`,
  };
  const config = options ? { ...defaults, ...options } : defaults;
  immediateToast(type, config);
};

const questionAlert = (
  title: string,
  message: string,
  buttonsText: AlertButtons = new AlertButtons(),
  options?: any
) => {
  return new Promise<boolean>((resolve, reject) => {
    try {
      const defaults = {
        theme: "dark",
        message: `<b>${message}</b>`,
        title: `<b>${title}</b>`,
        overlay: true,
        position: AlertPosition.center,
        timeout: 0,
        backgroundColor: "#565c70",
        progressBarColor: "#e74f4e",
        overlayClose: true,
        buttons: [
          [
            `<button><b>${buttonsText?.yesText}</b></button>`,
            (instance: any, toast: any) => {
              resolve(true);
              instance.hide({ transitionOut: "fadeOut" }, toast, "button");
            },
            true,
          ],
          [
            `<button>${buttonsText?.noText}</button>`,
            function (instance: any, toast: any) {
              resolve(false);
              instance.hide({ transitionOut: "fadeOut" }, toast, "button");
            },
            false,
          ],
        ],
      };
      const config = options ? { ...defaults, ...options } : defaults;
      immediateToast(AlertType.question, config);
    } catch (exception) {
      reject();
    }
  });
};

const alertFromAPIResponse = (
  response: IIPCResponse,
  customPosition?: AlertPosition
) => {
  if (response?.message) {
    const type = response?.success ? AlertType.success : AlertType.error;
    if (!response?.success) {
      showDetailErrorsAlert(response, customPosition);
    } else {
      immediateToast(type, {
        title: `INSEMAP`,
        theme: "dark",
        message: `<b>${response?.message}</b>`,
        position: customPosition ?? defaultPosition,
        backgroundColor: "#565c70",
        progressBarColor: "#7FE706",
      });
    }
  }
};

const showDetailErrorsAlert = (
  response: IIPCResponse,
  customPosition?: AlertPosition
) => {
  const lis = response?.errors?.map((item: any) => {
    return `
        <li class="containerError"><i class="iziToast-icon fa fa-times revealIn errorIcon"></i><b>${item}</b></li>
        `;
  });
  const html = `
    <div class="containerMainError">
        <p><b>${response?.message}</b></p>
        <span><b>Detalle:</b></span>
        <hr>
        <ul class="listOfErrorsAlert">${lis?.join("")}
        </ul>
    </div>`;
  immediateToast(AlertType.error, {
    id: "errorDetail",
    theme: "dark",
    title: `INSEMAP`,
    message: response?.errors?.length > 0 ? html : response?.message,
    position: customPosition ?? defaultPosition,
    timeout: 9000,
    backgroundColor: "#565c70",
    progressBarColor: "#e74f4e",
    overlayClose: true,
  });
};

const sweetQuestionAlert = (
  title: string,
  message: string,
  confirmButtonText?: string,
  cancelButtonText?: string,
  iconD?: string
) => {
  return new Promise<boolean>((resolve, reject) => {
    try {
      Swal.fire({
        title: title,
        text: message,
        icon: iconD as SweetAlertIcon ?? "question" as SweetAlertIcon,
        showCancelButton: true,
        confirmButtonColor: "#4287f5",
        cancelButtonColor: "#b5b5b1",
        confirmButtonText: confirmButtonText ?? "Sí",
        cancelButtonText: cancelButtonText ?? "No",
      }).then((result) => {
        if (result.isConfirmed) 
          resolve(true);
        else 
          resolve(false);
      });
    } catch (exception) {
      reject();
    }
  });
}

export { questionAlert, customAlert, alertFromAPIResponse, sweetQuestionAlert };