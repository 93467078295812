import axios from "axios";
import { API_BASE_URL } from "../config/APIEndpoints";
import { store } from "../store";

const axiosInstance = axios.create();
axiosInstance.defaults.timeout = 40000;
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstance.defaults.baseURL = `${API_BASE_URL}/api/v1`;

const axiosDefaultHeaders = (token: string) => {
  return {
    Authorization: `${token}`,
    Accept: "application/json",
    "Content-Type": "application/json"
  };
};


// Interceptor para enviar en todas las solicitudes
// toda la información  del usuario, terminal, tienda
// para el usuario que está logguiado.
axiosInstance.interceptors.request.use(function (config) {

  const auth = store.getState().auth;
  const { isLoggedIn, user, token } = auth;
  config.headers = axiosDefaultHeaders(token);
  if(isLoggedIn) {
    config.headers["userid"] = user.id;
    config.headers["username"] = user.username;
  }

  /*console.log(`isLoggedIn: ${isLoggedIn}`);
  console.log(`user: ${user}`);
  console.log(`token: ${token}`);*/
  return config;
});

export default axiosInstance;