import { lazy } from "react";

import Login from "../pages/auth/login/Login";
import Dashboard from "../pages/dashboard/Dashboard";

// import FruitTicketList from "../pages/fruit-ticket/FruitTicketList";
// import CustomerList from "../pages/maintenance/customers/CustomerList";
// import DriverList from "../pages/maintenance/drivers/DriverList";
// import PropertyList from "../pages/maintenance/properties/PropertyList";
// import UserList from "../pages/maintenance/users/UserList";
// import VehicleTypeList from "../pages/maintenance/vehicle-types/VehicleTypeList";

const LazyFruitTicketList = lazy(
  () =>
    import(
      "../pages/fruit-ticket/FruitTicketList"
    )
);

const LazyCustomerList = lazy(
  () =>
    import(
      "../pages/maintenance/customers/CustomerList"
    )
);

const LazyDriverList = lazy(
  () =>
    import(
      "../pages/maintenance/drivers/DriverList"
    )
);

const LazyPropertyList = lazy(
  () =>
    import(
      "../pages/maintenance/properties/PropertyList"
    )
);

const LazyUserList = lazy(
  () =>
    import(
      "../pages/maintenance/users/UserList"
    )
);

const LazyVehicleTypeList = lazy(
  () =>
    import(
      "../pages/maintenance/vehicle-types/VehicleTypeList"
    )
);


interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

export const LOGIN_PAGE = "/login";
export const DASHBOARD = "/";
export const USER_LIST_PAGE = "/mantenimientos/usuario/listado";
export const CUSTOMER_LIST_PAGE = "/mantenimientos/cliente/listado";
export const VEHICLE_TYPE_LIST_PAGE = "/mantenimientos/vehiculo/listado";
export const DRIVER_LIST_PAGE = "/mantenimientos/conductor/listado";
export const PROPERTY_LIST_PAGE = "/mantenimientos/finca/listado";
export const FRUIT_TICKET_LIST_PAGE = "/boletas/listado";

export const userRoutes: Array<RouteProps> = [
  { path: DASHBOARD, component: <Dashboard /> },
  { path: USER_LIST_PAGE, component: <LazyUserList /> },
  { path: CUSTOMER_LIST_PAGE, component: <LazyCustomerList /> },
  { path: VEHICLE_TYPE_LIST_PAGE, component: <LazyVehicleTypeList /> },
  { path: DRIVER_LIST_PAGE, component: <LazyDriverList /> },
  { path: PROPERTY_LIST_PAGE, component: <LazyPropertyList /> },

  { path: FRUIT_TICKET_LIST_PAGE, component: <LazyFruitTicketList /> },
];

export const authRoutes: Array<RouteProps> = [
  { path: LOGIN_PAGE, component: <Login /> }
];