import {configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import storage from "redux-persist/lib/storage";

import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  // transforms: [],
  // whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const middlewares: never[] = [];

const store = configureStore({
  reducer: persistedReducer,
  middleware: []
});

const persistor = persistStore(store);
export {store, persistor};

export type AppDispatch = typeof store.dispatch;