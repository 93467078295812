import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { RootState } from "../../../../store/reducers";
import { useAppDispatch } from "../../../../hooks/useAppSelector";
import { setIsLoggedIn, setToken, setUser } from "../../../../store/reducers/auth/authSlice";
import { LOGIN_PAGE } from "../../../../routes/routes";



const useDropdownProfile = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state: RootState) => state.auth)
  const [isLoading] = useState<boolean>(false);

  const handleLogOut = useCallback(() => {
    dispatch(setIsLoggedIn(false));
    dispatch(setUser(null));
    dispatch(setToken(""));
    navigate(LOGIN_PAGE)
  }, [dispatch, navigate]);

  return {
    isLoading,
    user,
    handleLogOut
  }
}

export default useDropdownProfile;