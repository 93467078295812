import React from "react";
import { Container, Row, Col } from "reactstrap";
import error from "../../assets/images/error-img.png";

import "../../assets/pages/NotFound.css";

const NotFound = () => {

  return (
    <React.Fragment>
      <div className="my-5 pt-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5">
                <h1 className="display-1 fw-semibold">
                  4<span className="text-primary mx-2">0</span>4
                </h1>
                <h4 className="text-uppercase">PÁGINA NO ENCONTRADA</h4>
                <div className="mt-5 text-center">
                  {/* <Link className="btn btn-primary" to="/dashboard">
                    Regresar al inicio
                  </Link> */}
                </div>
              </div>
            </Col>
          </Row>
          <div className="row justify-content-center">
            <div className="col-md-10 col-xl-8">
              <div>
                <img
                  src={error}
                  alt="INSEMAP Notfound"
                  className="img-fluid"
                  width="800"
                  height="429"
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NotFound;