"use client"
import React from "react";
import { IGroup, MenuType } from "../../../../models/ui/menu.model";
import { Link, useLocation } from 'react-router-dom';
import { SideBarMenu, PageAccess } from "./Sidebar.model";

interface IGroupProps {
  group: IGroup;
  pagesAccessURLS: string[];
  props?: any;
}

const SideBarItem = ({ group, pagesAccessURLS, ...props }: IGroupProps) => {
  let location = useLocation();
  let match =
    group.items.filter(item => item.pathURL === location.pathname).length > 0;
  // let icon = menu.icon && <div className="menu-icon"><i className={menu.icon}></i></div>;
  let icon = group.groupIcon && (
    <div className="menu-icon">
      <i className={group.groupIcon}></i>
    </div>
  );
  let caret = group.items && <div className="menu-caret"></div>;
  let title = group.name && <div className="menu-text">{group.name}</div>;

  return (
    <div
      className={
        "menu-item" + (match ? " active" : "") + (group.items ? " has-sub" : "")
      }
    >
      <Link className="menu-link" to="/#" {...props}>
        {icon} {title}
        {caret}
      </Link>

      {group.items && (
        // <div key={generateRandomValue()} className={'menu-submenu'}>
        <div key={group.groupId} className={"menu-submenu"}>
          {group.items
            .filter(item => pagesAccessURLS.includes(item.pathURL))
            .sort((a, b) => a.order - b.order)
            .map((item, i) => {
              let match = item.pathURL === location.pathname;
              return (
                <div key={i} className={"menu-item" + (match ? " active" : "")}>
                  <Link className="menu-link" to={item.pathURL} {...props}>
                    <div className="menu-item-icon">
                      <i className={item.itemIcon}></i>
                    </div> &nbsp;&nbsp;
                    {item.name}
                  </Link>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};


const SidebarNav = () => {
  // const menus = useSelector(getMenus());
  // const pagesAccess = useSelector(getPagesAccess());
  const menus: any = SideBarMenu;
  const pagesAccess:any = PageAccess;
  const pagesAccessURLS: string[] =
    pagesAccess?.map((page: any) => page.pageURL) || [];
  return (
    <div
      className="menu data-disable-slide-animation"
      data-disable-slide-animation="data-disable-slide-animation"
    >
      {menus
        ?.filter(menu => menu.menuType === MenuType.SIDEBAR)
        .map((menu, i) => {
          return (
            <React.Fragment key={i}>
              <div className="menu-header">{menu.name} </div>
              {menu.groups
                .filter(
                  group =>
                    group.items.filter(item =>
                      pagesAccessURLS.includes(item.pathURL)
                    ).length > 0
                )
                // { menu.groups.sort((a, b) => a.order - b.order).map((group: IGroup, i: number) => (
                .sort((a, b) => a.order - b.order)
                .map((group: IGroup, i: number) => (
                  <SideBarItem
                    key={i}
                    group={group}
                    pagesAccessURLS={pagesAccessURLS}
                  />
                ))}
            </React.Fragment>
          );
        })}
    </div>
  );
}

export default SidebarNav;