import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IParcel } from "../../../../../models/schema/property/property.model";

interface ParcelState {
  parcels: IParcel[];
  parcelToEdit: IParcel | null;
}

const initialState: ParcelState  = {
  parcels: [],
  parcelToEdit: null
}

const parcelSlice = createSlice({
  name: "Parcel",
  initialState,
  reducers: {
    setParcelList: (state, action: PayloadAction<IParcel[]>) => {
      state.parcels = action.payload;
    },
    concatListParcel: (state: ParcelState, action: PayloadAction<IParcel[]>) => {
      state.parcels = [...state.parcels.concat(action.payload)];
    },
    setParcelToEdit: (state, action: PayloadAction<IParcel | null>) => {
      state.parcelToEdit = action.payload;
    },
    addParcel: (state, action: PayloadAction<IParcel>) => {
      state.parcels.push(action.payload);
    },
    updateParcel: (state, action: PayloadAction<IParcel>) => {
      let itemIndex = state.parcels.findIndex(
        item => item.id === action.payload.id
      );
      if (itemIndex !== -1) state.parcels[itemIndex] = action.payload;
    },
    deleteParcel: (state, action: PayloadAction<number>) => {
      const newArray = state.parcels.filter(
        item => item.id !== action.payload
      );
      state.parcels = newArray;
    },
  },
});

export const {
  setParcelList,
  concatListParcel,
  setParcelToEdit,
  addParcel,
  updateParcel,
  deleteParcel,
} = parcelSlice.actions;

export default parcelSlice.reducer;