import { IMenu, IPageAccess } from "../../../../models/ui/menu.model";

export const SideBarMenu: IMenu[] = [
  {
    menuId: 0,
    menuIcon: "",
    name: "Menú INSEMAP",
    menuType: "SIDEBAR",
    order: 0,
    groups: [
      {
        groupId: 0,
        groupIcon: "fa-solid fa-gears",
        name: "Mantenimientos",
        description: "MANTENIMIENTOS",
        items: [
          {
            menuId: 0,
            itemIcon: "fa-solid fa-user-group",
            name: "Usuarios",
            pathURL: "/mantenimientos/usuario/listado",
            order: 0
          },
          {
            menuId: 0,
            itemIcon: "fa-solid fa-users",
            name: "Clientes",
            pathURL: "/mantenimientos/cliente/listado",
            order: 0
          },
          {
            menuId: 0,
            itemIcon: "fa-solid fa-truck",
            name: "Tipo Vehículo",
            pathURL: "/mantenimientos/vehiculo/listado",
            order: 0
          },
          {
            menuId: 0,
            itemIcon: "fa-solid fa-car",
            name: "Conductores",
            pathURL: "/mantenimientos/conductor/listado",
            order: 0
          },
          {
            menuId: 0,
            itemIcon: "fa-solid fa-compass",
            name: "Fincas",
            pathURL: "/mantenimientos/finca/listado",
            order: 0
          }
        ],
        order: 0
      },
      {
        groupId: 0,
        groupIcon: "fa-solid fa-folder-open",
        name: "Boletas",
        description: "TICKETS",
        items: [
          {
            menuId: 0,
            itemIcon: "fas fa-folder-open",
            name: "Boletas Entrega Fruta",
            pathURL: "/boletas/listado",
            order: 0
          }
        ],
        order: 0
      }
    ]
  }
];

export const PageAccess: IPageAccess[] = [
  { pageURL: "/mantenimientos/usuario/listado", elementRefs: ["btnView", "btnCreate", "btnUpdate", "btnDelete"] },
  { pageURL: "/mantenimientos/cliente/listado", elementRefs: ["btnView", "btnCreate", "btnUpdate", "btnDelete"] },
  { pageURL: "/mantenimientos/vehiculo/listado", elementRefs: ["btnView", "btnCreate", "btnUpdate", "btnDelete"] },
  { pageURL: "/mantenimientos/conductor/listado", elementRefs: ["btnView", "btnCreate", "btnUpdate", "btnDelete"] },
  { pageURL: "/mantenimientos/finca/listado", elementRefs: ["btnView", "btnCreate", "btnUpdate", "btnDelete"] },
  { pageURL: "/boletas/listado", elementRefs: ["btnView", "btnCreate", "btnUpdate", "btnDelete"] },
]