import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVehicleType } from "../../../../../models/schema/vehicle/vehicle.model";

interface VehicleTypeState {
  vehicleTypes: IVehicleType[];
  vehicleTypeToEdit: IVehicleType | null;
}

const initialState: VehicleTypeState  = {
  vehicleTypes: [],
  vehicleTypeToEdit: null
}

const vehicleTypeSlice = createSlice({
  name: "VehicleType",
  initialState,
  reducers: {
    setVehicleTypeList: (state, action: PayloadAction<IVehicleType[]>) => {
      state.vehicleTypes = action.payload;
    },
    concatListVehicleType: (state: VehicleTypeState, action: PayloadAction<IVehicleType[]>) => {
      state.vehicleTypes = [...state.vehicleTypes.concat(action.payload)];
    },
    setVehicleTypeToEdit: (state, action: PayloadAction<IVehicleType | null>) => {
      state.vehicleTypeToEdit = action.payload;
    },
    addVehicleType: (state, action: PayloadAction<IVehicleType>) => {
      state.vehicleTypes.push(action.payload);
    },
    updateVehicleType: (state, action: PayloadAction<IVehicleType>) => {
      let itemIndex = state.vehicleTypes.findIndex(
        item => item.id === action.payload.id
      );
      if (itemIndex !== -1) state.vehicleTypes[itemIndex] = action.payload;
    },
    deleteVehicleType: (state, action: PayloadAction<number>) => {
      const newArray = state.vehicleTypes.filter(
        item => item.id !== action.payload
      );
      state.vehicleTypes = newArray;
    },
  },
});

export const {
  setVehicleTypeList,
  concatListVehicleType,
  setVehicleTypeToEdit,
  addVehicleType,
  updateVehicleType,
  deleteVehicleType,
} = vehicleTypeSlice.actions;

export default vehicleTypeSlice.reducer;